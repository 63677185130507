import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faGlobe, faPlane, faSatelliteDish, faUsers } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Link } from "react-router-dom";
import * as S from "src/styles/main";
import SidebarItem, { SidebarItemModel } from "./SidebarItem";

function Sidebar() {
  const items: SidebarItemModel[] = [
    {
      name: "ARTCCs",
      path: "artccs",
      faIcon: faSatelliteDish,
    },
    {
      name: "Sessions",
      path: "sessions",
      faIcon: faUsers,
    },
    {
      name: "Aircraft",
      path: "aircraft",
      faIcon: faPlane,
    },
    {
      name: "vNAS Flight Plans",
      path: "flight-plans",
      faIcon: faClipboard,
    },
    {
      name: "FSD Flight Plans",
      path: "fsd-flight-plans",
      faIcon: faClipboard,
    },
    {
      name: "External Controllers",
      path: "external-controllers",
      faIcon: faGlobe,
    },
  ];

  return (
    <S.Sidebar className="main-sidebar elevation-4 sidebar-dark-primary position-fixed">
      <Link to="/" className="brand-link">
        <img src="/img/logo.png" alt="CRC Logo" className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">vNAS Dashboard</span>
      </Link>
      <div className="sidebar">
        <nav className="mt-2 overflow-hidden">
          <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent" role="menu">
            {items.map((item) => (
              <SidebarItem key={item.name} item={item} />
            ))}
          </ul>
        </nav>
      </div>
    </S.Sidebar>
  );
}

export default Sidebar;
