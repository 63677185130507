import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useAppSelector, vatsimCodeSelector, vatsimTokenSelector } from "src/redux";
import * as S from "src/styles/login";
import { DOMAIN, VATSIM_CLIENT_ID } from "src/utils";

const REDIRECT_URL = encodeURIComponent(`${DOMAIN}/login`);
const VATSIM_LOGIN_URL = `https://auth.vatsim.net/oauth/authorize?client_id=${VATSIM_CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&scope=vatsim_details`;

function VatsimLogin() {
  const vatsimCode = useAppSelector(vatsimCodeSelector);
  const vatsimToken = useAppSelector(vatsimTokenSelector);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    setIsLoggingIn(!!vatsimCode || !!vatsimToken);
  }, [vatsimCode, vatsimToken]);

  return (
    <>
      <img src="/img/logo.png" alt="vNAS" height="100" width="100" />
      <S.Title>vNAS Dashboard</S.Title>
      <button
        className="btn btn-success"
        type="button"
        style={{ minWidth: "150px" }}
        disabled={isLoggingIn}
        onClick={() => {
          setIsLoggingIn(true);
          window.location.href = VATSIM_LOGIN_URL;
        }}
      >
        {isLoggingIn ? <FontAwesomeIcon icon={faGear} className="fa-spin" /> : "Login with VATSIM"}
      </button>
    </>
  );
}

export default VatsimLogin;
