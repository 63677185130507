import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { SmallBox } from "src/components/ui";
import { nasStatusSelector, useAppSelector } from "src/redux";
import { pluralize } from "src/utils";

function Artccs() {
  const nasStatus = useAppSelector(nasStatusSelector);
  const [artccs, setArtccs] = useState(nasStatus?.artccHosts ?? []);

  useEffect(() => {
    if (nasStatus) {
      setArtccs([...nasStatus.artccHosts].sort((a, b) => a.id.localeCompare(b.id)));
    }
  }, [nasStatus?.artccHosts]);

  return (
    <>
      <h1 className="content-header">ARTCCs</h1>
      <section className="content">
        <Container fluid className="d-flex flex-wrap gap-3">
          {nasStatus &&
            artccs.map((artcc) => {
              const sessionCount = nasStatus.sessions.filter((s) => s.artccId === artcc.id).length;
              return (
                <SmallBox
                  key={artcc.id}
                  title={artcc.id}
                  subtitle1={`${sessionCount ? "Active" : "Inactive"} - ${sessionCount} ${pluralize("Session", sessionCount)}`}
                  subtitle2={`${artcc.aircraftCount} Aircraft`}
                  action="View"
                  faIcon={faSatelliteDish}
                  navigateTo={artcc.id}
                  type={nasStatus.sessions.find((s) => s.artccId === artcc.id) ? "success" : "secondary"}
                />
              );
            })}
        </Container>
      </section>
    </>
  );
}

export default Artccs;
