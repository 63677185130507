import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { EnvironmentOverview, Footer, Header, Sidebar } from "src/components/main";
import { ScreenSize } from "src/enums";
import {
  environmentIsDownSelector,
  environmentSelector,
  loadAircraft,
  loadExternalControllers,
  loadFlightPlans,
  loadFsdFlightPlans,
  loadNasStatus,
  nasTokenSelector,
  refreshNasToken,
  screenSizeSelector,
  sidebarIsCollapsedSelector,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import { REFRESH_DATA_MS, REFRESH_NAS_TOKEN_MS, addWindowClass, removeWindowClass } from "src/utils";

function Main() {
  const screenSize = useAppSelector(screenSizeSelector);
  const nasToken = useAppSelector(nasTokenSelector);
  const menuSidebarIsCollapsed = useAppSelector(sidebarIsCollapsedSelector);
  const environment = useAppSelector(environmentSelector);
  const environmentIsDown = useAppSelector(environmentIsDownSelector);
  const dispatch = useAppDispatch();

  const refreshData = () => {
    dispatch(loadNasStatus());
    dispatch(loadFlightPlans());
    dispatch(loadAircraft());
    dispatch(loadExternalControllers());
    dispatch(loadFsdFlightPlans());
  };

  useEffect(() => {
    let dataRefreshInterval: NodeJS.Timer;
    if (environment && !environmentIsDown && !environment.isDisabled) {
      refreshData();
      dataRefreshInterval = setInterval(() => {
        refreshData();
      }, REFRESH_DATA_MS);
    }

    return () => {
      clearInterval(dataRefreshInterval);
    };
  }, [environment, environmentIsDown]);

  useEffect(() => {
    let nasTokenRefreshInterval: NodeJS.Timer;
    if (nasToken) {
      nasTokenRefreshInterval = setInterval(() => {
        dispatch(refreshNasToken());
      }, REFRESH_NAS_TOKEN_MS);
    }
    return () => {
      clearInterval(nasTokenRefreshInterval);
    };
  }, [nasToken]);

  useEffect(() => {
    addWindowClass("sidebar-mini");
  }, []);

  useEffect(() => {
    removeWindowClass("sidebar-closed");
    removeWindowClass("sidebar-collapse");
    removeWindowClass("sidebar-open");
    if (menuSidebarIsCollapsed && screenSize === ScreenSize.Large) {
      addWindowClass("sidebar-collapse");
    } else if (menuSidebarIsCollapsed && screenSize === ScreenSize.Small) {
      addWindowClass("sidebar-open");
    } else if (!menuSidebarIsCollapsed && screenSize !== ScreenSize.Large) {
      addWindowClass("sidebar-closed");
      addWindowClass("sidebar-collapse");
    }
  }, [screenSize, menuSidebarIsCollapsed]);

  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper pb-4">
        <div className="pt-3" />
        <section className="content">
          {environment && <EnvironmentOverview />}
          <Outlet />
        </section>
      </div>
      <Footer />
      <div id="sidebar-overlay" role="presentation" onClick={() => dispatch(toggleSidebar())} />
    </div>
  );
}

export default Main;
