import { faClipboard, faPlane, faUsers } from "@fortawesome/free-solid-svg-icons";
import React, { useMemo } from "react";
import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BackBar, InfoBox, TableNoRows } from "src/components/ui";
import { flightPlansSelector, nasStatusSelector, useAppSelector } from "src/redux";

function Artcc() {
  const id = useParams().id!;
  const nasStatus = useAppSelector(nasStatusSelector);
  const allFlightPlans = useAppSelector(flightPlansSelector);

  const artccHostStatus = nasStatus ? nasStatus.artccHosts.find((h) => h.id === id) : undefined;
  const sessions = nasStatus ? nasStatus.sessions.filter((s) => s.artccId === id) : [];
  const flightPlans = allFlightPlans ? allFlightPlans[id] : undefined;

  const openPositionFeeds = useMemo(() => {
    if (!artccHostStatus) {
      return [];
    }
    return [...artccHostStatus.openPositionFeeds].sort((a, b) => a.facilityId.localeCompare(b.facilityId));
  }, [artccHostStatus?.openPositionFeeds]);

  const towerCabFeeds = useMemo(() => {
    if (!artccHostStatus) {
      return [];
    }
    return [...artccHostStatus.towerCabFeeds].sort((a, b) => a.facilityId.localeCompare(b.facilityId));
  }, [artccHostStatus?.towerCabFeeds]);

  const flightStripFeeds = useMemo(() => {
    if (!artccHostStatus) {
      return [];
    }
    return [...artccHostStatus.flightStripFeeds].sort((a, b) => a.facilityId.localeCompare(b.facilityId));
  }, [artccHostStatus?.flightStripFeeds]);

  const asdexTargetFeeds = useMemo(() => {
    if (!artccHostStatus) {
      return [];
    }
    return [...artccHostStatus.asdexTargetFeeds].sort((a, b) => a.facilityId.localeCompare(b.facilityId));
  }, [artccHostStatus?.asdexTargetFeeds]);

  return (
    <>
      <h1 className="content-header">
        {id}
        <span className="ml-3">
          <Badge bg={sessions.length ? "success" : "secondary"}>{sessions.length ? "Active" : "Inactive"}</Badge>
        </span>
      </h1>
      {nasStatus && (
        <section className="content">
          <Container fluid>
            <Row>
              <Col lg>
                <InfoBox
                  subtitle={`Session${sessions?.length !== 1 ? "s" : ""}`}
                  title={sessions ? sessions.length.toString() : "0"}
                  icon={faUsers}
                  navigateTo={`/sessions?artccs=["${id}"]`}
                />
              </Col>
              <Col lg>
                <InfoBox
                  subtitle="Aircraft"
                  title={nasStatus.artccHosts.find((a) => a.id === id)!.aircraftCount.toString()}
                  icon={faPlane}
                  navigateTo={`/aircraft?artccs=["${id}"]`}
                />
              </Col>
              <Col lg>
                <InfoBox
                  subtitle={`Flight Plan${flightPlans?.length !== 1 ? "s" : ""}`}
                  title={flightPlans ? flightPlans.length.toString() : "0"}
                  icon={faClipboard}
                  navigateTo={`/flight-plans?artccs=["${id}"]`}
                />
              </Col>
            </Row>

            <h2>Feeds</h2>
            <Row>
              <Col lg>
                <Card>
                  <Card.Header>
                    <Card.Title>Open Position Feeds</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped className="table-head-fixed">
                      <thead>
                        <tr>
                          <th>Facility</th>
                          <th>Positions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openPositionFeeds.map((feed) => (
                          <tr key={feed.facilityId}>
                            <td>{feed.facilityId}</td>
                            <td>{feed.entryCount}</td>
                          </tr>
                        ))}
                        <TableNoRows rows={openPositionFeeds} text="No Open Position Feeds" />
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg>
                <Card>
                  <Card.Header>
                    <Card.Title>Flight Strip Feeds</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped className="table-head-fixed">
                      <thead>
                        <tr>
                          <th>Facility</th>
                          <th>Bay Items</th>
                          <th>Printer Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flightStripFeeds.map((feed) => (
                          <tr key={feed.facilityId}>
                            <td>{feed.facilityId}</td>
                            <td>{feed.bayItemCount}</td>
                            <td>{feed.printerItemCount}</td>
                          </tr>
                        ))}
                        <TableNoRows rows={flightStripFeeds} text="No Flight Strip Feeds" />
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg>
                <Card>
                  <Card.Header>
                    <Card.Title>Tower Cab Feeds</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped className="table-head-fixed">
                      <thead>
                        <tr>
                          <th>Facility</th>
                          <th>Aircraft</th>
                        </tr>
                      </thead>
                      <tbody>
                        {towerCabFeeds.map((feed) => (
                          <tr key={feed.facilityId}>
                            <td>{feed.facilityId}</td>
                            <td>{feed.aircraftCount}</td>
                          </tr>
                        ))}
                        <TableNoRows rows={towerCabFeeds} text="No Tower Cab Feeds" />
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg>
                <Card>
                  <Card.Header>
                    <Card.Title>ASDE-X Feeds</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive striped className="table-head-fixed">
                      <thead>
                        <tr>
                          <th>Facility</th>
                          <th>Targets</th>
                        </tr>
                      </thead>
                      <tbody>
                        {asdexTargetFeeds.map((feed) => (
                          <tr key={feed.facilityId}>
                            <td>{feed.facilityId}</td>
                            <td>{feed.targetCount}</td>
                          </tr>
                        ))}
                        <TableNoRows rows={asdexTargetFeeds} text="No ASDE-X Target Feeds" />
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <BackBar backText="Back to ARTCCs" />
    </>
  );
}

export default Artcc;
