import { faClipboard, faClock, faPlaneDeparture, faRepeat, faRoute, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { voiceTypeToString } from "@vatsim-vnas/js-libs/models/vnas/common";
import { FlightPlan, FlightPlanStatus } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { beaconCodeToString, booleanToString, dateToZuluString } from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { Badge, Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface FlightPlanModalProps {
  flightPlan: FlightPlan | undefined;
  artccId: string | undefined;
  show: boolean;
  onClose: () => void;
}

function getFlightPlanStatusColor(status: FlightPlanStatus) {
  switch (status) {
    case FlightPlanStatus.Active:
      return "success";
    case FlightPlanStatus.Tentative:
      return "warning";
    case FlightPlanStatus.Proposed:
    default:
      return "secondary";
  }
}

function FlightPlanModal({ flightPlan, artccId, show, onClose }: FlightPlanModalProps) {
  const navigate = useNavigate();

  if (!flightPlan || !artccId) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        {flightPlan && (
          <Modal.Title className="d-flex align-items-center">
            {flightPlan.aircraftId} - {artccId}
            <Badge bg={getFlightPlanStatusColor(flightPlan.status)} className="ml-2">
              {flightPlan.status}
            </Badge>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="dark-mode d-flex flex-wrap gap-3">
        {flightPlan && (
          <>
            <Card className="flex-grow-1">
              <Card.Header>
                <Card.Title>
                  <FontAwesomeIcon icon={faClipboard} className="mr-2" />
                  General
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    Aircraft ID: <b>{flightPlan.aircraftId}</b>
                  </Col>
                  <Col md className="mb-3">
                    Revision: <b>{flightPlan.revision}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md className="mb-3">
                    CID: <b>{flightPlan.cid}</b>
                  </Col>
                  <Col md className="mb-3">
                    Beacon code: <b>{beaconCodeToString(flightPlan.assignedBeaconCode ?? 0)}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md className="mb-3">
                    Has connected: <b>{booleanToString(flightPlan.hasConnected)}</b>
                  </Col>
                  <Col md className="mb-3">
                    Arrival strip printed: <b>{booleanToString(flightPlan.arrivalStripPrinted)}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Checked for auto activate: <b>{booleanToString(flightPlan.checkedForAutoActivate)}</b>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card className="flex-grow-1">
              <Card.Header>
                <Card.Title>
                  <FontAwesomeIcon icon={faRoute} className="mr-2" />
                  Routing
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    Departure: <b>{flightPlan.departure}</b>
                  </Col>
                  <Col md className="mb-3">
                    Destination: <b>{flightPlan.destination}</b>
                  </Col>
                  <Col md className="mb-3">
                    Alternate: <b>{flightPlan.alternate}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md className="mb-3">
                    Altitude: FL<b>{flightPlan.altitude}</b>
                  </Col>
                  <Col md className="mb-3">
                    Speed: <b>{flightPlan.speed}</b> kts
                  </Col>
                  <Col />
                </Row>
                <Row>
                  <Col>
                    Route: <b>{flightPlan.route}</b>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card className="flex-grow-1">
              <Card.Header>
                <Card.Title>
                  <FontAwesomeIcon icon={faWrench} className="mr-2" />
                  Equipment
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    Aircraft type: <b>{flightPlan.aircraftType}</b>
                  </Col>
                  <Col md className="mb-3">
                    Heavy: <b>{booleanToString(flightPlan.isHeavy)}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md className="mb-3">
                    Equipment: <b>{flightPlan.equipment}</b>
                  </Col>
                  <Col md className="mb-3">
                    Wake turbulence code: <b>{flightPlan.wakeTurbulenceCode}</b>
                  </Col>
                </Row>
                <Row>
                  <Col lg className="mb-3">
                    FAA equipment code: <b>{flightPlan.faaEquipmentSuffix}</b>
                  </Col>
                  <Col lg className="mb-3">
                    ICAO equipment codes: <b>{flightPlan.icaoEquipmentCodes}</b>
                  </Col>
                  <Col>
                    ICAO surveillance codes: <b>{flightPlan.icaoSurveillanceCodes}</b>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card className="flex-grow-1">
              <Card.Header>
                <Card.Title>
                  <FontAwesomeIcon icon={faClock} className="mr-2" />
                  Timing
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    Estimated departure time: <b>{flightPlan.estimatedDepartureTime.toString().padStart(4, "0")}</b>
                  </Col>
                  <Col md className="mb-3">
                    Actual departure time: <b>{flightPlan.actualDepartureTime.toString().padStart(4, "0")}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md className="mb-3">
                    Time enroute:{" "}
                    <b>
                      {flightPlan.hoursEnroute}h{flightPlan.minutesEnroute}m
                    </b>
                  </Col>
                  <Col md className="mb-3">
                    Available fuel:{" "}
                    <b>
                      {flightPlan.fuelHours}h{flightPlan.fuelMinutes}m
                    </b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Estmated arrival time: <b>{flightPlan.estimatedArrivalTime ? dateToZuluString(flightPlan.estimatedArrivalTime) : ""}</b>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card className="flex-grow-1">
              <Card.Header>
                <Card.Title>
                  <i className="fa-solid fa-user-pilot mr-2" />
                  Pilot
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="mb-3">
                    Voice type: <b>{voiceTypeToString(flightPlan.voiceType)}</b>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    Remarks: <b>{flightPlan.remarks}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Stripped remarks: <b>{flightPlan.strippedRemarks}</b>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card className="flex-grow-1">
              <Card.Header>
                <Card.Title>
                  <FontAwesomeIcon icon={faPlaneDeparture} className="mr-2" />
                  TDLS
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    Cleared: <b>{booleanToString(!!flightPlan.clearance)}</b>
                  </Col>
                  <Col className="mb-3">
                    Dumped: <b>{booleanToString(flightPlan.tdlsDumped)}</b>
                  </Col>
                </Row>
                {flightPlan.clearance && (
                  <Row>
                    <Col className="d-flex flex-wrap gx-3">
                      <span>
                        SID: <b>{flightPlan.clearance.sid}</b>
                      </span>
                      <span>
                        Transition: <b>{flightPlan.clearance.transition}</b>
                      </span>
                      <span>
                        Climb out: <b>{flightPlan.clearance.climbout}</b>
                      </span>
                      <span>
                        Climb via: <b>{flightPlan.clearance.climbvia}</b>
                      </span>
                      <span>
                        Initial altitude: <b>{flightPlan.clearance.initialAlt}</b>
                      </span>
                      <span>
                        Expect: <b>{flightPlan.clearance.expect}</b>
                      </span>
                      <span>
                        Departure frequency: <b>{flightPlan.clearance.depFreq}</b>
                      </span>
                      <span>
                        Contact info: <b>{flightPlan.clearance.contactInfo}</b>
                      </span>
                      <span>
                        Local info: <b>{flightPlan.clearance.localInfo}</b>
                      </span>
                    </Col>
                  </Row>
                )}
              </Card.Body>
              <Card.Footer />
            </Card>
            {flightPlan.holdAnnotations && (
              <Card className="flex-grow-1">
                <Card.Header>
                  <Card.Title>
                    <FontAwesomeIcon icon={faRepeat} className="mr-2" />
                    Hold Annotations
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      Fix: <b>{flightPlan.holdAnnotations.fix}</b>
                    </Col>
                    <Col className="mb-3">
                      EFC: <b>{flightPlan.holdAnnotations.efc}Z</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col md className="mb-3">
                      Direction: <b>{flightPlan.holdAnnotations.direction}</b>
                    </Col>
                    <Col md className="mb-3">
                      Turns: <b>{flightPlan.holdAnnotations.turns}</b>
                    </Col>
                    <Col md>
                      Legs:{" "}
                      <b>
                        {flightPlan.holdAnnotations.legLength} {flightPlan.holdAnnotations.legLengthInNm ? " NM" : " mins"}
                      </b>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer />
              </Card>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-success" type="button" onClick={() => navigate(`/aircraft?open=${flightPlan.aircraftId}`)}>
          View Aircraft
        </button>
        <button className="btn btn-success" type="button" onClick={() => navigate(`/fsd-flight-plans?open=${flightPlan.aircraftId}`)}>
          View FSD Flight Plan
        </button>
        <button className="btn btn-primary" onClick={onClose} type="button">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default FlightPlanModal;
