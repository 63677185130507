import React from "react";

const YEAR = new Date().getFullYear();

function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        <span>Copyright © {YEAR} Nathan Rankin</span>
      </strong>
    </footer>
  );
}

export default Footer;
