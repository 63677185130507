import "react-bootstrap-typeahead/css/Typeahead.css";
import { createGlobalStyle } from "styled-components";
import "./imports.css";

const GlobalStyle = createGlobalStyle`
    .main-header .navbar-nav .nav-item .nav-link {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .table td {
        vertical-align: middle !important;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .rbt-token {
        background: grey !important;
        color: white !important;
    }

    input.rbt-input-main {
        color: white !important;
    }

    .gap-3 {
        gap: 1rem !important;
    }

    .gx-3 {
        column-gap: 1rem !important;
    }

    .w-0 {
        width: 0 !important;
    }
`;

export default GlobalStyle;
