import { getServerInfoAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { distinct } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  aircraftSelector,
  environmentSelector,
  externalControllersSelector,
  fsdFlightPlansSelector,
  nasStatusSelector,
  setEnvironmentIsDown,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import { REFRESH_DATA_MS } from "src/utils";

function EnvironmentOverview() {
  const environment = useAppSelector(environmentSelector)!;
  const nasStatus = useAppSelector(nasStatusSelector);
  const aircraft = useAppSelector(aircraftSelector);
  const fsdFlightPlans = useAppSelector(fsdFlightPlansSelector);
  const externalControllers = useAppSelector(externalControllersSelector);
  const dispatch = useAppDispatch();

  const [color, setColor] = useState("grey");
  const [version, setVersion] = useState("Unknown");
  const [status, setStatus] = useState("Unknown");

  const getStatus = async () => {
    if (environment.isDisabled) {
      setColor("grey");
      setStatus("Disabled");
      setVersion("Unknown");
      dispatch(setEnvironmentIsDown(true));
    } else {
      try {
        const res = await getServerInfoAsync(environment.apiBaseUrl);
        if (res.ok) {
          setColor("green");
          setVersion(res.data!.version);
          setStatus("Operational");
          dispatch(setEnvironmentIsDown(false));
        } else {
          setColor("red");
          setStatus("Down");
          setVersion("Unknown");
          dispatch(setEnvironmentIsDown(true));
        }
      } catch {
        setColor("red");
        setStatus("Down");
        setVersion("Unknown");
        dispatch(setEnvironmentIsDown(true));
      }
    }
  };

  useEffect(() => {
    getStatus();
    const interval = setInterval(() => {
      getStatus();
    }, REFRESH_DATA_MS);

    return () => clearInterval(interval);
  }, [environment]);

  return (
    <Card>
      <Card.Header>
        <div className="card-title">
          <h1>vNAS - {environment.name}</h1>
        </div>
      </Card.Header>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h5>
              Version: <b>{version}</b>
            </h5>
          </div>
          <div className="col">
            {nasStatus && (
              <h5>
                Active ARTCCs:{" "}
                <b>
                  {nasStatus.sessions.map((s) => s.artccId).filter(distinct).length}/{nasStatus.artccHosts.length}
                </b>
              </h5>
            )}
          </div>
          <div className="col">
            {aircraft && (
              <h5>
                Aircraft: <b>{aircraft.length}</b>
              </h5>
            )}
          </div>
          <div className="col">
            {fsdFlightPlans && (
              <h5>
                FSD Flight Plans: <b>{fsdFlightPlans.length}</b>
              </h5>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5>
              Status: <b style={{ color }}>{status}</b>
            </h5>
          </div>
          <div className="col">
            {nasStatus && (
              <h5>
                Sessions: <b>{nasStatus.sessions.length}</b>
              </h5>
            )}
          </div>
          <div className="col">
            {externalControllers && (
              <h5>
                External Controllers: <b>{externalControllers.length}</b>
              </h5>
            )}
          </div>
          <div className="col" />
        </div>
      </div>
      <Card.Footer />
    </Card>
  );
}

export default EnvironmentOverview;
