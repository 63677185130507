import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as S from "src/styles/ui";

interface SmallBoxProps {
  title: string;
  subtitle1: string;
  subtitle2: string;
  action: string;
  faIcon: IconProp;
  navigateTo: string;
  type: "success" | "secondary";
}

function SmallBox({ title, subtitle1, subtitle2, action, faIcon, navigateTo, type }: SmallBoxProps) {
  return (
    <S.SmallBox to={navigateTo} className={`small-box bg-${type}`}>
      <div className="inner">
        <h3>{title}&nbsp;</h3>
        <p>{subtitle1}</p>
        <p>{subtitle2}</p>
      </div>
      <div className="icon">
        <FontAwesomeIcon icon={faIcon} />
      </div>
      <div className="small-box-footer">
        <span className="mr-2">{action}</span>
        <i className="fa fa-arrow-circle-right" />
      </div>
    </S.SmallBox>
  );
}

export default SmallBox;
