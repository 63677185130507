import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { loggedInSelector } from "src/redux/slices/authSlice";
import { useAppSelector } from "../redux/hooks";

function PrivateRoute() {
  const location = useLocation();
  const loggedIn = useAppSelector(loggedInSelector);

  if (!loggedIn && location.pathname !== "/") {
    localStorage.setItem("login-next", `${location.pathname}${location.search}`);
  }

  if (loggedIn) {
    localStorage.removeItem("login-next");
  }

  return loggedIn ? <Outlet /> : <Navigate to="/login" replace />;
}

export default PrivateRoute;
