import React from "react";
import * as S from "src/styles/login";
import VatsimLogin from "./VatsimLogin";

function LoginSidebar() {
  return (
    <S.LoginSidebar>
      <S.LoginPanel>
        <VatsimLogin />
      </S.LoginPanel>
      <S.Footer>&nbsp;</S.Footer>
    </S.LoginSidebar>
  );
}

export default LoginSidebar;
