import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExternalController } from "@vatsim-vnas/js-libs/models/vnas/external-controllers";
import { booleanToString, dateToZuluString, frequencyToString } from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";

interface ExternalControllerModalProps {
  controller: ExternalController | undefined;
  show: boolean;
  onClose: () => void;
}

function ExternalControllerModal({ controller, show, onClose }: ExternalControllerModalProps) {
  if (!controller) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>{controller.callsign}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode d-flex flex-wrap gap-3">
        <Card className="flex-grow-1">
          <Card.Header>
            <Card.Title>
              <FontAwesomeIcon icon={faGlobe} className="mr-2" />
              General
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md className="mb-3">
                Callsign: <b>{controller.callsign}</b>
              </Col>
              <Col md className="mb-3">
                Frequency: <b>{frequencyToString(controller.frequency)}</b>
              </Col>
            </Row>
            <Row>
              <Col md className="mb-3">
                Real name: <b>{controller.realName}</b>
              </Col>
              <Col md className="mb-3">
                Hidden: <b>{booleanToString(controller.isHidden)}</b>
              </Col>
            </Row>
            <Row>
              <Col md className="mb-3">
                Last updated: <b>{dateToZuluString(controller.lastUpdatedAt)}</b>
              </Col>
              <Col md className="mb-3">
                Position update count: <b>{controller.positionUpdateCount}</b>
              </Col>
            </Row>
            <Row>
              <Col>Visibility Centers:</Col>
            </Row>
            {!!controller.visibilityCenters.length &&
              controller.visibilityCenters
                .filter((v) => v)
                .map((v) => (
                  <Row key={`${v.lat}${v.lon}`}>
                    <Col>
                      <>
                        <b>{v.lat}</b>&deg;,&nbsp;<b>{v.lon}</b>&deg;
                      </>
                    </Col>
                  </Row>
                ))}
          </Card.Body>
          <Card.Footer />
        </Card>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" onClick={onClose} type="button">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExternalControllerModal;
