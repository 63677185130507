import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EnvironmentButton from "src/components/main/EnvironmentButton";
import { availableEnvironmentsSelector, logout, toggleSidebar, useAppDispatch, useAppSelector } from "src/redux";

function Header() {
  const availableEnvironments = useAppSelector(availableEnvironmentsSelector);
  const dispatch = useAppDispatch();

  return (
    <nav className="main-header navbar navbar-expand navbar-dark">
      <ul className="navbar-nav">
        <li className="nav-item">
          <button onClick={() => dispatch(toggleSidebar())} type="button" className="nav-link">
            <FontAwesomeIcon icon={faBars} />
          </button>
        </li>
      </ul>
      <ul className="navbar-nav d-flex justify-content-around w-100">
        {availableEnvironments.map((e) => (
          <EnvironmentButton environment={e} key={e.name} />
        ))}
      </ul>
      <ul className="navbar-nav ml-auto d-flex align-items-center">
        <button type="button" className="btn btn-default btn-flat text-nowrap" onClick={() => dispatch(logout())}>
          Log Out
        </button>
      </ul>
    </nav>
  );
}

export default Header;
