import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAircraftStatusAsync,
  getExternalControllersStatusAsync,
  getFlightPlansStatusAsync,
  getFsdFlightPlansStatusAsync,
  getNasStatusAsync,
  getVnasConfigurationAsync,
} from "@vatsim-vnas/js-libs/api/vnas";
import { Environment } from "@vatsim-vnas/js-libs/models/vnas";
import { Aircraft } from "@vatsim-vnas/js-libs/models/vnas/aircraft";
import { ExternalController } from "@vatsim-vnas/js-libs/models/vnas/external-controllers";
import { FlightPlan } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { FsdFlightPlan } from "@vatsim-vnas/js-libs/models/vnas/fsd";
import { NasStatus } from "@vatsim-vnas/js-libs/models/vnas/status";
import { toast } from "react-toastify";
import { processResponse } from "src/utils";
import { RootState } from "../store";

interface StatusSlice {
  environment?: Environment;
  environmentIsDown: boolean;
  availableEnvironments: Environment[];
  nasStatus?: NasStatus;
  flightPlans?: Record<string, FlightPlan[]>;
  fsdFlightPlans: FsdFlightPlan[];
  aircraft: Aircraft[];
  externalControllers: ExternalController[];
}

const initialState: StatusSlice = {
  availableEnvironments: [],
  environmentIsDown: false,
  fsdFlightPlans: [],
  aircraft: [],
  externalControllers: [],
};

export const getVnasConfiguration = createAsyncThunk("auth/getVnasConfiguration", async () => {
  const res = await getVnasConfigurationAsync();
  return processResponse(res, "Failed to get vNAS configuration");
});

export const loadNasStatus = createAsyncThunk("data/loadNasStatus", async (_, thunkApi) => {
  const environment = (thunkApi.getState() as RootState).status.environment!;
  const nasToken = (thunkApi.getState() as RootState).auth.nasToken!;
  const res = await getNasStatusAsync(environment.apiBaseUrl, nasToken);
  return res.data;
});

export const loadFlightPlans = createAsyncThunk("data/loadFlightPlans", async (_, thunkApi) => {
  const environment = (thunkApi.getState() as RootState).status.environment!;
  const nasToken = (thunkApi.getState() as RootState).auth.nasToken!;
  const res = await getFlightPlansStatusAsync(environment.apiBaseUrl, nasToken);
  return res.data;
});

export const loadFsdFlightPlans = createAsyncThunk("data/loadFsdFlightPlans", async (_, thunkApi) => {
  const environment = (thunkApi.getState() as RootState).status.environment!;
  const nasToken = (thunkApi.getState() as RootState).auth.nasToken!;
  const res = await getFsdFlightPlansStatusAsync(environment.apiBaseUrl, nasToken);
  return res.data ?? [];
});

export const loadAircraft = createAsyncThunk("data/loadAircraft", async (_, thunkApi) => {
  const environment = (thunkApi.getState() as RootState).status.environment!;
  const nasToken = (thunkApi.getState() as RootState).auth.nasToken!;
  const res = await getAircraftStatusAsync(environment.apiBaseUrl, nasToken);
  return res.data ?? [];
});

export const loadExternalControllers = createAsyncThunk("data/loadExternalControllerse", async (_, thunkApi) => {
  const environment = (thunkApi.getState() as RootState).status.environment!;
  const nasToken = (thunkApi.getState() as RootState).auth.nasToken!;
  const res = await getExternalControllersStatusAsync(environment.apiBaseUrl, nasToken);
  return res.data ?? [];
});

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setEnvironment: (state, action: PayloadAction<string>) => {
      state.environment = state.availableEnvironments.find((e) => e.name === action.payload)!;
      state.nasStatus = undefined;
      state.fsdFlightPlans = [];
      state.aircraft = [];
      state.externalControllers = [];
      localStorage.setItem("environment", action.payload);
    },
    setEnvironmentIsDown: (state, action: PayloadAction<boolean>) => {
      state.environmentIsDown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVnasConfiguration.fulfilled, (state, action) => {
      if (action.payload) {
        const availableEnvironments = action.payload.environments;
        state.availableEnvironments = availableEnvironments;
        const localEnvironment = localStorage.getItem("environment");
        if (localEnvironment) {
          const availableEnvironment = availableEnvironments.find((e) => e.name === localEnvironment);
          if (availableEnvironment) {
            state.environment = availableEnvironment;
          } else {
            localStorage.removeItem("environment");
            state.environment = availableEnvironments[0]!;
          }
        } else {
          state.environment = availableEnvironments[0]!;
        }
      }
    });
    builder.addCase(getVnasConfiguration.rejected, (_, action) => {
      toast.error(`Failed to get vNAS configuration: ${action.error.message}`);
    });
    builder.addCase(loadNasStatus.fulfilled, (state, action) => {
      state.nasStatus = action.payload;
    });
    builder.addCase(loadNasStatus.rejected, (state) => {
      state.nasStatus = undefined;
    });
    builder.addCase(loadFlightPlans.fulfilled, (state, action) => {
      state.flightPlans = action.payload;
    });
    builder.addCase(loadFlightPlans.rejected, (state) => {
      state.flightPlans = undefined;
    });
    builder.addCase(loadFsdFlightPlans.fulfilled, (state, action) => {
      state.fsdFlightPlans = action.payload;
    });
    builder.addCase(loadFsdFlightPlans.rejected, (state) => {
      state.fsdFlightPlans = [];
    });
    builder.addCase(loadAircraft.fulfilled, (state, action) => {
      state.aircraft = action.payload;
    });
    builder.addCase(loadAircraft.rejected, (state) => {
      state.aircraft = [];
    });
    builder.addCase(loadExternalControllers.fulfilled, (state, action) => {
      state.externalControllers = action.payload;
    });
    builder.addCase(loadExternalControllers.rejected, (state) => {
      state.externalControllers = [];
    });
  },
});

export const { setEnvironment, setEnvironmentIsDown } = statusSlice.actions;
export const availableEnvironmentsSelector = (state: RootState) => state.status.availableEnvironments;
export const environmentSelector = (state: RootState) => state.status.environment;
export const environmentIsDownSelector = (state: RootState) => state.status.environmentIsDown;
export const nasStatusSelector = (state: RootState) => state.status.nasStatus;
export const flightPlansSelector = (state: RootState) => state.status.flightPlans;
export const fsdFlightPlansSelector = (state: RootState) => state.status.fsdFlightPlans;
export const aircraftSelector = (state: RootState) => state.status.aircraft;
export const externalControllersSelector = (state: RootState) => state.status.externalControllers;

export default statusSlice.reducer;
