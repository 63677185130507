import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "src/styles/ui";

function BackBar({ backText }: { backText: string }) {
  const navigate = useNavigate();

  return (
    <S.BackBar className="card">
      <div className="row card-body">
        <button className="btn btn-secondary" type="button" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} className="mr-2" /> {backText}
        </button>
      </div>
    </S.BackBar>
  );
}

export default BackBar;
