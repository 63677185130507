import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import * as S from "src/styles/ui";

interface InfoBoxProps {
  title: string;
  subtitle: string;
  icon: IconProp;
  navigateTo: string;
}

function InfoBox({ title, subtitle, icon, navigateTo }: InfoBoxProps) {
  return (
    <Link to={navigateTo}>
      <S.ScaleIconContainer className="info-box">
        <span className="info-box-icon elevation-1 bg-primary">
          <FontAwesomeIcon icon={icon} />
        </span>
        <div className="info-box-content">
          <span className="info-box-number">{title}</span>
          <span className="info-box-text">{subtitle}</span>
        </div>
        <S.SmallBackgroundIcon icon={faChevronRight} className="icon" />
      </S.ScaleIconContainer>
    </Link>
  );
}

export default InfoBox;
