import { faGauge, faPlane, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Aircraft } from "@vatsim-vnas/js-libs/models/vnas/aircraft";
import { voiceTypeToString } from "@vatsim-vnas/js-libs/models/vnas/common";
import { beaconCodeToString, booleanToString, dateToZuluString } from "@vatsim-vnas/js-libs/utils";
import React from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fsdFlightPlansSelector, useAppSelector } from "src/redux";
import { getArtccIdList } from "src/utils";

interface AircraftModalProps {
  aircraft: Aircraft | undefined;
  show: boolean;
  onClose: () => void;
}

function AircraftModal({ aircraft, show, onClose }: AircraftModalProps) {
  const flightPlans = useAppSelector(fsdFlightPlansSelector);
  const navigate = useNavigate();

  if (!aircraft) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>{aircraft.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode d-flex flex-wrap gap-3">
        <>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faPlane} className="mr-2" />
                General
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md className="mb-3">
                  Aircraft ID: <b>{aircraft.id}</b>
                </Col>
                <Col md className="mb-3">
                  Last updated: <b>{dateToZuluString(aircraft.lastUpdatedAt)}</b>
                </Col>
              </Row>
              <Row>
                <Col md className="mb-3">
                  Newly connected: <b>{booleanToString(aircraft.isNewlyConnected)}</b>
                </Col>
                <Col md className="mb-3">
                  Type code query sent: <b>{booleanToString(aircraft.typeCodeQuerySent)}</b>
                </Col>
              </Row>
              <Row>
                <Col md className="mb-3">
                  Hidden: <b>{booleanToString(aircraft.isHidden)}</b>
                </Col>
                <Col md className="mb-3">
                  Slow position count: <b>{aircraft.slowPositionCount}</b>
                </Col>
              </Row>
              <Row>
                <Col mb className="mb-3">
                  Within ARTCC&apos;s Surveillance: <b>{getArtccIdList(aircraft.isWithinSurveillanceAoi).join(", ")}</b>
                </Col>
              </Row>
              <Row>
                <Col mb>
                  W1ithin ARTCC&apos;s Flight Data: <b>{getArtccIdList(aircraft.isWithinFlightDataAoi).join(", ")}</b>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <i className="fa-solid fa-user-pilot mr-2" />
                Pilot
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mb-3">
                  User ID: <b>{aircraft.pilotUserId}</b>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  Name: <b>{aircraft.realName}</b>
                </Col>
              </Row>
              <Row>
                <Col className="text-nowrap">
                  Voice Type: <b>{voiceTypeToString(aircraft.voiceType)}</b>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faGauge} className="mr-2" />
                Telemetry
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg className="mb-3">
                  Location:{" "}
                  {aircraft.location && (
                    <>
                      <b>{aircraft.location.lat}</b>&deg;,&nbsp;<b>{aircraft.location.lon}</b>&deg;
                    </>
                  )}
                </Col>
                <Col lg className="mb-3">
                  Heading: <b>{Math.round(aircraft.heading)}</b>&deg;
                </Col>
                <Col lg className="mb-3">
                  Ground speed: <b>{aircraft.groundSpeed}</b> kts
                </Col>
              </Row>
              <Row>
                <Col lg className="mb-3">
                  True altitude: <b>{aircraft.altitudeTrue}</b> ft
                </Col>
                <Col lg className="mb-3">
                  Pressure altitude: <b>{aircraft.altitudePressure}</b> ft
                </Col>
                <Col lg>
                  Absolute altitude: <b>{Math.round(aircraft.altitudeAgl)}</b> ft
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
          <Card className="flex-grow-1">
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faWrench} className="mr-2" />
                Equipment
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md className="mb-3">
                  Aircraft type: <b>{aircraft.typeCode}</b>
                </Col>
                <Col md className="mb-3">
                  Heavy: <b>{booleanToString(aircraft.isHeavy)}</b>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  Transponder mode: <b>{aircraft.transponderMode}</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  Beacon code: <b>{aircraft.reportedBeaconCode !== null && beaconCodeToString(aircraft.reportedBeaconCode!)}</b>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
        </>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        {flightPlans.find((f) => f.aircraftId === aircraft.id) && (
          <button className="btn btn-success" type="button" onClick={() => navigate(`/fsd-flight-plans?open=${aircraft.id}`)}>
            View FSD Flight Plan
          </button>
        )}
        <button className="btn btn-primary" onClick={onClose} type="button">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AircraftModal;
