import { Response } from "@vatsim-vnas/js-libs/models/api";
import { toast } from "react-toastify";

export function addWindowClass(classList: string) {
  const window = document?.getElementById("root");
  if (window) {
    window.classList.add(classList);
  }
}

export function removeWindowClass(classList: string) {
  const window = document?.getElementById("root");
  if (window) {
    window.classList.remove(classList);
  }
}

export function getArtccIdList(artccs: Record<string, boolean>) {
  return Object.entries(artccs)
    .filter((e) => e[1])
    .map((e) => e[0]);
}

export function processResponse<T>(res: Response<T>, errorMessage: string): T | undefined {
  if (res.ok) {
    return res.data!;
  }
  toast.error(`${errorMessage}: ${res.statusText}`);
  return undefined;
}

export function pluralize(word: string, count: number) {
  return `${word}${count === 1 ? "" : "s"}`;
}
