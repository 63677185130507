import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { TableNoRows } from "src/components/ui";
import { ExternalControllerModal } from "src/components/ui/modal";
import { externalControllersSelector, useAppSelector } from "src/redux";

function ExternalControllers() {
  const externalControllers = useAppSelector(externalControllersSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [showModal, setShowModal] = useState(false);
  const [selectedControllerCallsign, setSelectedControllerCallsign] = useState<string>();

  const visibleControllers = useMemo(
    () =>
      externalControllers.filter((c) => c.callsign.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.callsign.localeCompare(b.callsign)),
    [JSON.stringify(externalControllers), search],
  );

  return (
    <div>
      <h1 className="content-header">External Controllers</h1>
      <section className="content">
        <Card>
          <Card.Header>
            <Card.Title>External Controllers</Card.Title>
            <div className="card-tools d-flex">
              <input className="form-control" value={search} onChange={(e) => setSearchParams({ search: e.target.value })} placeholder="Search..." />
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive striped className="table-head-fixed">
              <thead>
                <tr>
                  <th>Callsign</th>
                  <th className="w-0">View</th>
                </tr>
              </thead>
              <tbody>
                {visibleControllers.map((c) => (
                  <tr key={c.callsign}>
                    <td>{c.callsign}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedControllerCallsign(c.callsign);
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                ))}
                <TableNoRows rows={externalControllers} text="No External Controllers" />
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer />
        </Card>
      </section>
      <ExternalControllerModal
        show={showModal}
        controller={externalControllers.find((c) => c.callsign === selectedControllerCallsign)}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

export default ExternalControllers;
