import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginSidebar } from "src/components/login";
import {
  availableEnvironmentsSelector,
  loggedInSelector,
  login,
  nasTokenSelector,
  refreshNasToken,
  setVatsimCode,
  useAppDispatch,
  useAppSelector,
  vatsimTokenSelector,
} from "src/redux";
import * as S from "src/styles/login";
import { DOMAIN } from "src/utils";

function Login() {
  const vatsimToken = useAppSelector(vatsimTokenSelector);
  const loggedIn = useAppSelector(loggedInSelector);
  const nasToken = useAppSelector(nasTokenSelector);
  const availableEnvironments = useAppSelector(availableEnvironmentsSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = useRef(searchParams.get("code"));
  const loginNext = localStorage.getItem("login-next");

  useEffect(() => {
    if (code.current && availableEnvironments.length) {
      dispatch(setVatsimCode(code.current));
      dispatch(login({ code: code.current, redirectUrl: encodeURIComponent(`${DOMAIN}/login`) }));
      code.current = undefined!;
    }
  }, [availableEnvironments]);

  useEffect(() => {
    if (code && vatsimToken) {
      navigate("/login", { replace: true });
    }
  }, [vatsimToken]);

  useEffect(() => {
    if (vatsimToken && !nasToken && availableEnvironments.length) {
      dispatch(refreshNasToken());
    }
  }, [vatsimToken, nasToken, availableEnvironments]);

  useEffect(() => {
    if (loggedIn) {
      if (loginNext) {
        navigate(loginNext);
        localStorage.removeItem("login-next");
      } else {
        navigate("/");
      }
    }
  }, [loggedIn]);

  return (
    <>
      <S.LoginBackground />
      <LoginSidebar />
    </>
  );
}

export default Login;
