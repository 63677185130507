import { getServerInfoAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Environment } from "@vatsim-vnas/js-libs/models/vnas";
import React, { useEffect, useState } from "react";
import { environmentSelector, setEnvironment, useAppDispatch, useAppSelector } from "src/redux";
import * as S from "src/styles/main";
import { REFRESH_DATA_MS } from "src/utils";

function EnvironmentButton({ environment }: { environment: Environment }) {
  const selected = useAppSelector(environmentSelector) === environment;
  const dispatch = useAppDispatch();
  const [color, setColor] = useState("grey");

  const getStatus = async () => {
    if (environment.isDisabled) {
      setColor("grey");
    } else {
      try {
        const res = await getServerInfoAsync(environment.apiBaseUrl);
        if (res.ok) {
          setColor("green");
        } else {
          setColor("red");
        }
      } catch {
        setColor("red");
      }
    }
  };

  useEffect(() => {
    getStatus();
    const interval = setInterval(() => {
      getStatus();
    }, REFRESH_DATA_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <S.EnvironmentButton onClick={() => dispatch(setEnvironment(environment.name))} selected={selected}>
      <S.StatusIndicator color={color} />
      <S.StatusLabel>{environment.name}</S.StatusLabel>
    </S.EnvironmentButton>
  );
}

export default EnvironmentButton;
