import React from "react";
import { Container } from "react-bootstrap";
import * as S from "src/styles/ui";

function PageNotFound() {
  return (
    <Container fluid className="vh-100 vw-100 text-center p-5">
      <S.ErrorCode data-text="404">404</S.ErrorCode>
      <p className="lead mb-5">Page Not Found</p>
      <p className="mb-0">This is not the page you&apos;re looking for...</p>
    </Container>
  );
}

export default PageNotFound;
