import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "src/redux";
import { GlobalStyle } from "src/styles";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <App />
      <ToastContainer position="top-right" autoClose={60000} toastStyle={{ color: "white", backgroundColor: "#202427" }} />
    </Provider>
  </StrictMode>,
);
