import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const ButtonText = styled.b`
  color: #17a2b8;
  &:hover {
    color: #45b5c6;
  }
`;

export const StaticText = styled.b`
  color: #17a2b8;
  margin-left: 16px;
`;

export const ArtccMenu = styled.div`
  padding: 15px;
  width: 250px;
`;

export const ArtccMenuItem = styled.div`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const UserHeader = styled.li`
  padding: 10px;
  text-align: center;
`;

export const SidebarButton = styled(Link)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  text-align: left;
`;

export const EnvironmentButton = styled.a<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  color: white !important;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;

  &:hover {
    text-decoration: underline;
  }

  ${(p) =>
    p.selected &&
    css`
      background-color: #3f6791;
      box-shadow:
        0 1px 3px rgb(0 0 0 / 12%),
        0 1px 2px rgb(0 0 0 / 24%);
    `}
`;

export const StatusIndicator = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${(p) => p.color};
  border-radius: 50%;
`;

export const StatusLabel = styled.span`
  margin-left: 5px;
  white-space: nowrap;
  font-weight: bold;
`;

export const Sidebar = styled.aside`
  margin-top: 57px;
`;
