import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ScreenSize, { calculateScreenSize } from "src/enums/ScreenSize";
import { RootState } from "../store";

interface UiState {
  screenSize: ScreenSize;
  sidebarIsCollapsed: boolean;
}

const initialState: UiState = {
  screenSize: calculateScreenSize(window.innerWidth),
  sidebarIsCollapsed: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setScreenSize: (state, action: PayloadAction<ScreenSize>) => {
      state.screenSize = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarIsCollapsed = !state.sidebarIsCollapsed;
    },
  },
});

export const { setScreenSize, toggleSidebar } = uiSlice.actions;
export const screenSizeSelector = (state: RootState) => state.ui.screenSize;
export const sidebarIsCollapsedSelector = (state: RootState) => state.ui.sidebarIsCollapsed;

export default uiSlice.reducer;
